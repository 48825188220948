import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';

import { CategoriaActividadDto } from 'src/app/models/categoria-actividad-dto';
import { ActividadDto } from 'src/app/models/actividad-dto';

import { ActividadesCategoriasService } from 'src/app/services/actividades-categorias.service';
import { ActividadesService } from 'src/app/services/actividades.service';

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.scss'],
  providers: [ConfirmationService]
})
export class ActividadesComponent implements OnInit {

  @ViewChild('dt') table: Table;

  /**
   * Marca de operación en curso
   */
  public loaded: boolean;

   //Títulos
  title: string;
  caption: string;

  actividades: ActividadDto[] = [];
  actividad: any;
  errorMessage: any = '';
  options: any;
  loading: boolean;
  first = 0;
  rows = 25;
  markers: any[];
  numServicios: number;

  map: google.maps.Map;
  categorias: CategoriaActividadDto[];
  cats: SelectItem[];

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private actividadesService: ActividadesService,
    private categoriasActividadService: ActividadesCategoriasService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('actividades_title');
    this.caption = this.locale.transform('actividades_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    this.reloadDataCategorias();
  }

  setMap(event) {
    this.map = event.map;
  }

  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
   childSelection(value: string) {
    switch(value) {
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {

            this.loadingService.setLoading();

              this.authService.logout(
                (resp: boolean) => {

                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
        });
        break;
      }
      default:
        break;
    }
  }

  reloadDataActividades() {
    this.actividadesService.getActividadesList().subscribe(
      async poi => {
        this.numServicios = poi.length;
        this.actividades = poi.sort(function sortFunction(a, b){
          const dateA = a.startDate;
          const dateB = b.startDate;

          if (dateA < Date.now() && dateB < Date.now()) {
              // Si ambos son eventos pasados, ordena en orden descendente
              return dateB - dateA;
          } else if (dateA >= Date.now() && dateB >= Date.now()) {
              // Si ambos son eventos futuros, ordena en orden ascendente
              return dateA - dateB;
          } else {
              // Si uno es futuro y el otro pasado, el futuro va primero
              return dateA < Date.now() ? 1 : -1;
          }
        });
        // ocultar pantalla de carga
        setTimeout(() => {
          this.loadingService.hideLoading();
        });
      },
      error => this.errorMessage = <any>error
    );
  }

  reloadDataCategorias() {
    this.categoriasActividadService.getCategoriasActividadesList().subscribe(
      cats => {
        this.categorias = cats;
        this.categorias.sort(function sortFunction(a, b){
          return a.name > b.name ? 1 : -1;
        });
        this.cats = [];
        this.categorias.forEach(cat => {
          var appItem: SelectItem = { label: cat.name, value: cat.name, icon: cat.icon}
          this.cats.push(appItem);
        });
        this.reloadDataActividades();
      },
      error => this.errorMessage = <any>error
    );
  }

  onTableEvent(event: any) {
    if (event.filteredValue) {
      
      //this.drawMarkers(event.filteredValue, 0, this.rows);
    }

    // Estamos ordenando
    if(event.order) {
      //this.drawMarkers(this.table.value, this.first, this.rows);
    }
  }

  onPageChange(event: any) {
    if (this.table.filteredValue) {
      
      //this.drawMarkers(this.table.filteredValue, event.first, event.rows);
    } else{
      //this.drawMarkers(this.table.value, event.first, event.rows);
    }
  }

  showActividadInfo(actividad: ActividadDto) {
    this.router.navigateByUrl('/actividad/' + actividad.id);
  }

}
