import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ActividadDto } from 'src/app/models/actividad-dto';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ActividadesService } from 'src/app/services/actividades.service';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';

@Component({
  selector: 'app-actividad-detail',
  templateUrl: './actividad-detail.component.html',
  styleUrls: ['./actividad-detail.component.scss'],
  providers: [ConfirmationService]
})
export class ActividadDetailComponent implements OnInit {

  /**
   * Marca de operación en curso
  */
   public loaded: boolean;

  //Títulos
  title: string;
  caption: string;

  options: any;

  actividad: ActividadDto;
  errorMessage: any = '';
  loading: boolean;

  constructor(private locale: LocalePipe,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private actividadesService: ActividadesService) { }

  ngOnInit(): void {
    // establecer no cargado hasta inicialización de componente
    this.loaded = false;
  
    // Añadir titulos a la cabecera
    this.title = this.locale.transform('actividades_title');
    this.caption = this.locale.transform('actividades_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    if(this.route.snapshot.paramMap.get('id')) {
      this.route.params.subscribe((params: Params) => {
        this.actividadesService.getActividadDetail(params['id']).subscribe(
          activity => {
            this.actividad = activity;
            setTimeout(() => {
              this.loadingService.hideLoading();
            });
          },
          error => this.errorMessage = <any>error)
      });
    }
  }

  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
   childSelection(value: string) {
    switch(value) {
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {

            this.loadingService.setLoading();

              this.authService.logout(
                (resp: boolean) => {

                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
        });
        break;
      }
      default:
        break;
    }
  }
}
