<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header (newItemEvent)="childSelection($event)"></app-header>
    <!-- CAROUSEL -->
    <ngb-carousel class="home-carousel" #carousel *ngIf="images" [interval]="7000" [pauseOnHover]="true">
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
            <img [src]="images[0]" alt="Random first slide">
          </div>
          <div class="carousel-caption">
            <h3>Camino a la Cruz, camino de amor</h3>
            <p>La Credencial de siempre, con un nuevo formato</p>
          </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img [src]="images[1]" alt="Random second slide">
            </div>
            <div class="carousel-caption">
                <h3>Registra tu paso por el Camino</h3>
                <p>Nuevo sistema de geolocalización de sellos</p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img [src]="images[2]" alt="Random third slide">
            </div>
            <div class="carousel-caption">
                <h3>Una experiencia única</h3>
                <p>Imprime la credencial de tu paso por el Camino</p>
            </div>
        </ng-template>
    </ngb-carousel>
    <!-- END CAROUSEL -->


    <!-- FEATURES -->
    <section class="features">
        <div class="contain">
            <div class="feature-item">
                <fa-icon [icon]="faRegistro"></fa-icon>
                <div class="texto">
                    <h3><span>{{'feature_registro_title'|locale}}</span></h3>
                    <p>{{'feature_registro_text'|locale}}</p>
                </div>
            </div>
            <div class="feature-item">
                <fa-icon [icon]="faCaminos"></fa-icon>
                <div class="texto">
                    <h3><span>{{'feature_camino_title'|locale}}</span></h3>
                    <p>{{'feature_camino_text'|locale}}</p>
                </div>
            </div>
            <div class="feature-item">
                <fa-icon [icon]="faServicios"></fa-icon>
                <div class="texto">
                    <h3><span>{{'feature_servicios_title'|locale}}</span></h3>
                    <p>{{'feature_servicios_text'|locale}}</p>
                </div>
            </div>
            <div class="feature-item">
                <fa-icon [icon]="faIncidencias"></fa-icon>
                <div class="texto">
                    <h3><span>{{'feature_incidencias_title'|locale}}</span></h3>
                    <p>{{'feature_incidencias_text'|locale}}</p>
                </div>
            </div>
        </div>
    </section>
    <!-- END FEATURES -->

    <!-- ROUTES -->
    <section class="routes">
        <div class="route-item item-1">
            <h3 class="big-heading">CAMINOS</h3>
            <p class="content">Empieza desde tu ciudad de origen</p>
        </div>
        <div class="route-item item-2">
            <h3 class="big-heading">MEDIOS <sup class="subtour"> A pie, En bici...</sup></h3>
            <p class="content">La credencial se adapta a ti</p>
        </div>
        <div class="route-item item-3">
            <h3 class="big-heading">DOCUMENTACIÓN</h3>
            <p class="content">Información de interés actualizada</p>
        </div>
    </section>
    <!-- END ROUTES -->

    <!-- MAP -->
    <section class="map">
        <div class="contain">
            <div class="img-map">
                <img alt="top-rated" class="img-responsive" src="../../../assets/img/smartphone_05 map.png">
            </div>
            <div class="info-map">
                <h3 class="big-heading">App oficial de la Credencial Digital del Camino de la Cruz</h3>
                <span class="sub-heading-content">Vive la pasión del Camino</span>
                <span class="devider-left"></span>
                <p>Descarga la aplicación oficial para adquirir tus Credenciales, sellar tu paso por el Camino, y ¡mucho más!:</p>
                <div class="stores-content">
                    <div class="store-apple" (click)="openAppStore()">
                        <img alt="top-rated" class="img-responsive" src="../../../assets/img/appstore.png">
                    </div>
                    <div class="store-android" (click)="openGooglePlay()">
                        <img alt="top-rated" class="img-responsive" src="../../../assets/img/googleplay.png">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>