<div class="header-content" *ngIf="sessionService.isLogged() == false" #stickyMenu [ngClass]="this.sticky == true ? 'sticky' : ''">
  <div class="subnav">
    <div class="left">
      <span><a href="mailto:soporte@credencialdigitalcaravaca.com" alt="email soporte">soporte@credencialdigitalcaravaca.com</a></span>
    </div>
    <div class="right">
      <div id="sub-icon" class="social-icons-subnav">
        <!--<a href="https://www.facebook.com/revistaperegrino?fref=ts"><fa-icon [icon]="faFacebook"></fa-icon></a>
        <a href="https://twitter.com/Federacion_CS"><fa-icon [icon]="faTwitter"></fa-icon></a>-->
      </div>
      <div class="social-icons-subnav" *ngIf="this.active != 5">
        <a routerLink="/login" routerLinkActive="active" alt="login"><fa-icon [icon]="faLock"></fa-icon><span>{{'menu_login'|locale}}</span></a>
      </div>    
      <div class="social-icons-subnav" *ngIf="this.active != 6">
        <a routerLink="/register" routerLinkActive="active" alt="register"><fa-icon [icon]="faUser"></fa-icon><span>{{'menu_signup'|locale}}</span></a>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-md">
    <div class="brand">
      <a routerLink="/home" routerLinkActive="active" alt="home"><img src="../../../assets/img/logo-camin-white.png"/></a>
    </div>
    <button class="navbar-toggler" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed" aria-label="Toggle navigation" (click)="collapsed = !collapsed">
      <fa-icon [icon]="faMenu"></fa-icon>
    </button>
  
    <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
      <ul class="navbar-nav ml-auto">

        <li class="nav-item">
          <a routerLink="/project" routerLinkActive="active" class="nav-link" tabindex="0" [ngClass]="this.active == 0 ? 'active' : ''">
            {{'menu_public_project'|locale}}
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="/servicios" routerLinkActive="active" class="nav-link" tabindex="1" [ngClass]="this.active == 1 ? 'active' : ''">
            {{'menu_private_servicios'|locale}}
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="/actividades" routerLinkActive="active" class="nav-link" tabindex="2" [ngClass]="this.active == 2 ? 'active' : ''">
            {{'menu_private_eventos'|locale}}
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="/contact" routerLinkActive="active" class="nav-link" tabindex="3" [ngClass]="this.active == 3 ? 'active' : ''">
            {{'menu_public_contact'|locale}}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div class="header-content" *ngIf="sessionService.isLogged() == true" #stickyMenu [ngClass]="this.sticky == true ? 'sticky' : ''">
  <p-confirmDialog header="Cerrar sesión" icon="pi pi-exclamation-triangle"></p-confirmDialog>
  <div class="subnav">
    <div class="left">
      <span><a href="mailto:soporte@credencialdigitalcaravaca.com" alt="email soporte">soporte@credencialdigitalcaravaca.com</a></span>
    </div>
    <div class="right">
      <div class="social-icons-subnav logged">
        <span>{{'menu_private_session'|locale}} {{name}} {{lastName}}</span>
      </div>    
      <div class="social-icons-subnav hidden-sm hidden-xs">
        <a (click)="passDataToParent('logout')"><fa-icon [icon]="faLogout"></fa-icon><span>{{'menu_private_logout'|locale}}</span></a>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-md">
    <div class="brand">
      <a routerLink="/dashboard" routerLinkActive="active" alt="home"><img src="../../../assets/img/logo-camin-white.png"/></a>
    </div>
    <button class="navbar-toggler" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed" aria-label="Toggle navigation" (click)="collapsed = !collapsed">
      <fa-icon [icon]="faMenu"></fa-icon>
    </button>
  
    <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
      <ul class="navbar-nav ml-auto">

        <li class="nav-item">
          <a routerLink="/registro" routerLinkActive="active" class="nav-link" tabindex="0" [ngClass]="this.active == 0 ? 'active' : ''">
            {{'menu_private_registro'|locale}}
          </a>
        </li>
  
        <li class="nav-item">
          <a routerLink="/servicios" routerLinkActive="active" class="nav-link" tabindex="1" [ngClass]="this.active == 1 ? 'active' : ''">
            {{'menu_private_servicios'|locale}}
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="/actividades" routerLinkActive="active" class="nav-link" tabindex="2" [ngClass]="this.active == 2 ? 'active' : ''">
            {{'menu_private_eventos'|locale}}
          </a>
        </li>

        <li class="nav-item">
          <a routerLink="/contact" routerLinkActive="active" class="nav-link" tabindex="3" [ngClass]="this.active == 3 ? 'active' : ''">
            {{'menu_public_contact'|locale}}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>