const baseUrl = 'https://api.credencialdigitalcaravaca.com/api/v1';

export const environment = {
  production: false,

  // AuthService
  apiAuthLoginUrl: `${baseUrl}/auth/login`,
  apiAuthCheckUrl: `${baseUrl}/auth/check`,
  apiAuthLogoutUrl: `${baseUrl}/auth/logout`,
  apiAuthRecoverUrl: `${baseUrl}/auth/reset-password`,
  apiAuthConfirmUrl: `${baseUrl}/auth/confirm-reset`,
  apiAuthRegisterUrl: `${baseUrl}/auth/register`,

  // PoiCategoryService
  apiPoiCategoryListUrl: `${baseUrl}/poi-category/public`,
  apiPoiCategoryDetailUrl: `${baseUrl}/poi-category`,

  // PoiService
  apiPoiListUrl: `${baseUrl}/poi/public`,
  apiPoiDetailUrl: `${baseUrl}/poi/`,
  apiPoiImportUrl: `${baseUrl}/config/import-pois`,

  // EventCategoryService
  apiEventCategoryListUrl: `${baseUrl}/event-category`,

  // EventService
  apiEventListUrl: `${baseUrl}/event`,

  // CredentialService
  apiCredentialListUrl: `${baseUrl}/credential/user`,
  apiCredentialDetailUrl: `${baseUrl}/credential`,
  apiCredentialDownloadUrl: `${baseUrl}/credential/pdf`,
  apiCredentialDownloadAlbumUrl: `${baseUrl}/credential/credential-album`,

  // WayService
  apiWayListUrl: `${baseUrl}/ways`,
  
  // ModeService
  apiModeListUrl: `${baseUrl}/ways/modes`,
};
