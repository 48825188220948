<div class="main-content">
    <app-header [active]=2 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <div class="title-content">
        <h2>Relación de actividades disponibles</h2>
        <span class="subtitle">Consulta el detalle de los servicios que encontrarás a lo largo del Camino. Teléfonos, horarios,...  todo a tu alcance</span>
    </div>
    <section class="servicios-content">
        <div class="table-section">
            <p-table #dt [(value)]="actividades" (onPage)="onPageChange($event)"
            [paginator]="true" paginatorPosition = "bottom" [autoLayout]="true" [rows]="rows" [showCurrentPageReport]="true" [loading]="loading" [(first)]="first"
            dataKey="id" [filterDelay]="0" (onFilter)="onTableEvent($event)" (onSort)="onTableEvent($event)" currentPageReportTemplate="{first} - {last} de {totalRecords} actividades">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="nombre">Nombre<p-sortIcon field="name"></p-sortIcon></th>
                        <th pSortableColumn="categoria">Categoría<p-sortIcon field="category.name"></p-sortIcon></th>
                        <th pSortableColumn="localidad">Fecha de inicio<p-sortIcon field="startDate"></p-sortIcon></th>
                        <th pSortableColumn="provincia">Fecha de fin<p-sortIcon field="endDate"></p-sortIcon></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th><input pInputText type="text" (input)="dt.filter($event.target?$event.target.value:'', 'name', 'startsWith')" placeholder="Nombre" class="p-column-filter"></th>
                        <th>
                            <p-dropdown [options]="cats" (onChange)="dt.filter($event.value, 'category.name', 'equals')" styleClass="p-column-filter" placeholder="Seleccionar" [showClear]="true">
                            <ng-template let-option pTemplate="item">
                                <img [alt]="option.label" src="{{option.icon}}" width="32" style="vertical-align: middle" />
                                <span class="p-ml-1">{{option.label}}</span>
                            </ng-template>
                            </p-dropdown>
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-actividad>
                    <tr>
                        <td class="center">
                            <div class="servicio-image">
                                <img *ngIf="actividad.image == null" src="assets/img/placeholder.png" alt="imagen de la actividad"/>
                                <img *ngIf="actividad.image != null" [src]="actividad.image" alt="imagen de la actividad"/>
                            </div>
                        </td>
                        <td>{{actividad.name}}</td>
                        <td><img [alt]="actividad.category.name" src="{{actividad.category.icon}}" width="32" style="vertical-align: middle" />{{actividad.category.name}}</td>
                        <td>{{actividad.startDate | date: 'dd/MM/yyyy'}}</td>
                        <td>{{actividad.endDate | date: 'dd/MM/yyyy'}}</td>
                        <td>
                            <div class="servicio-actions">
                                <button (click)="showActividadInfo(actividad)">MÁS INFORMACIÓN</button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </section>
    <app-footer></app-footer>
</div>