import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import  { WebServiceUtils } from '../utils/web-service-utils';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActividadesService {

  constructor(private http: HttpClient) { }

  /**
   * Listado de servicios disponibles
   * @param onSuccess respuesta OK (datos de servicios)
   * @param onError respuesta KO
   */
  public getActividadesList(): Observable<any> {
    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE
      }
    };
    return this.http.get(environment.apiEventListUrl, opts);
  }

  /**
   * Detalle de un servicio
   * @param idServicio identificador del servicio
   * @param onSuccess respuesta OK (datos del servicio)
   * @param onError respuesta KO
   */
  public getActividadDetail(id: number): Observable<any> {
    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE
      }
    };
    return this.http.get(environment.apiEventListUrl + '/' + id, opts);
  }
}
