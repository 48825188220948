import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthInfo } from '../models/auth-info';
import { GlobalResponse } from '../models/global-response';

import  { WsSuccessFunction, WsErrorFunction, WebServiceUtils } from '../utils/web-service-utils';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
import { RegisterFormInfo } from '../components/register/register.component';
import { UserDto } from '../models/user-dto';

const options = {
  headers: {
    'Content-Type': WebServiceUtils.CONTENT_TYPE
  }
};

@Injectable({ providedIn: 'root' })
export class AuthService {

  /**
   * Constructor por defecto
   * @param http Interfaz http
   */
  constructor(private http: HttpClient,
    private sessionService: SessionService) { }

  /**
   * Inicio de sesión con credenciales
   * @param email email de usuario
   * @param password contraseña
   * @param onSuccess respuesta OK (datos de usuario)
   * @param onError respuesta KO
   */
  public login(email: string, password: string, onSuccess:WsSuccessFunction<AuthInfo>, onError: WsErrorFunction<boolean>): void {

    const body = {
      email: email,
      password: password,
      aplicacion: ''
    };

    this.http.post<AuthInfo>(environment.apiAuthLoginUrl, body, options).subscribe(
      resp => {
        resp.token != null ? onSuccess(resp) : onError(true, null);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Recuperar info del usuario (validación)
   * @param token token propio
   * @param onSuccess respuesta OK (usuario)
   * @param onError respuesta KO
   */
  public check(token: string, onSuccess: WsSuccessFunction<AuthInfo>, onError: WsErrorFunction<boolean>): void {

    const body = { 
      token: token
    };

    this.http.post<AuthInfo>(environment.apiAuthCheckUrl, body, options).subscribe(
      resp => {
        (resp.valid != null && resp.valid == true) ? onSuccess(resp) : onError(true, null);
       },
      (err: HttpErrorResponse) => { onError(null, err)}
    );
  }

  /**
   * Cerrar sesión del usuario
   */
  public logout(onSuccess: WsSuccessFunction<boolean>, onError: WsErrorFunction<boolean>): void {

    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo().accessToken
      }
    };

    this.http.get<boolean>(environment.apiAuthLogoutUrl, opts).subscribe(
      resp => {
        onSuccess(resp);
      },
      (err: HttpErrorResponse) => { onError(null, err)}
    )
  }

  /**
   * Registro en el sistema con credenciales
   * @param userInfo información de usuario
   * @param onSuccess respuesta OK (registro)
   * @param onError respuesta KO
   */
   public register(userInfo: RegisterFormInfo, onSuccess:WsSuccessFunction<AuthInfo>, onError: WsErrorFunction<boolean>): void {

    const body = {
      firstname: userInfo.nombre,
      lastname: userInfo.apellidos,
      dni: userInfo.dni,
      email: userInfo.email,
      phone: userInfo.telefono,
      city: userInfo.ciudad,
      country: userInfo.pais,
      password: userInfo.password
    };

    this.http.post<AuthInfo>(environment.apiAuthRegisterUrl, body, options).subscribe(
      resp => {
        (resp.token != null) ? onSuccess(resp) : onError(true, null);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Inicio de recuperación de contraseña
   * @param email email de usuario
   * @param onSuccess respuesta OK (datos de usuario)
   * @param onError respuesta KO
   */
   public recoverPassword(email: string, onSuccess:WsSuccessFunction<AuthInfo>, onError: WsErrorFunction<boolean>): void {

    const body = {
      email: email
    };

    this.http.post<AuthInfo>(environment.apiAuthRecoverUrl, body, options).subscribe(
      resp => {
        resp.status == 200 ? onSuccess(resp) : onError(true, null);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }

  /**
   * Confirmación de recuperación de contraseña
   * @param token token jwt del email
   * @param password nueva contraseña
   * @param onSuccess respuesta OK (contraseña cambiada)
   * @param onError respuesta KO
   */
   public confirmPassword(token: string, password: string, onSuccess:WsSuccessFunction<AuthInfo>, onError: WsErrorFunction<boolean>): void {

    const body = {
      token: token,
      password: password
    };

    this.http.post<AuthInfo>(environment.apiAuthConfirmUrl, body, options).subscribe(
      resp => {
        resp.status == 200 ? onSuccess(resp) : onError(true, null);
      },
      (err: HttpErrorResponse) => {
        onError(null, err);
      }
    )
  }
}
