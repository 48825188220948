<div class="main-content">
    <app-header [active]=0 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="registro-content">
        <div class="title-content">
            <h2>Relación de credenciales adquiridas</h2>
            <span class="subtitle">Puedes ver el estado de las credenciales compradas o activadas desde la aplicación, descargarlas.. ¡y mucho más!</span>
        </div>
        <div class="credenciales-content">
            <div class="single-cred-content" *ngFor="let cred of credenciales" routerLink="/credencial/{{ cred.id }}">
                <p-card>
                    <ng-template pTemplate="header">
                        <div class="title"><span class="pi pi-calendar"></span>{{cred.purchaseDate | date: 'dd/MM/yyyy hh:mm'}}</div>
                    </ng-template> 
                    <p-gmap #gmap (onMapReady)="setMap($event, cred)" (onMapClick)="handleMapClick($event, cred)" [options]="options" [overlays]="cred.markers" [style]="{'width':'100%','height':'200px'}"></p-gmap>
                    <div class="aforo"><span class="pi pi-briefcase"></span>{{cred.camino.name}}</div>
                    <div class="route-mode">
                        <div class="place"><span class="pi pi-home"></span>{{cred.address}}</div>
                        <div class="mode"><span class="pi pi-directions"></span>{{cred.medio.name}}</div>
                    </div>
                    <div class="state-unvalidate" *ngIf="cred.status == 0">CREDENCIAL SIN VALIDAR</div>
                    <div class="state-validate" *ngIf="cred.status != 0">CREDENCIAL VALIDADA</div>
                </p-card>
                <!--
                <div class="route-mode">
                    <div class="place"><span class="pi pi-map-marker"></span>{{cred.lugar}}</div>
                    <div class="place"><span class="pi pi-map-marker"></span>{{cred.medio.nombre}}</div>
                </div>
                <div class="aforo"><span class="pi pi-briefcase"></span>{{cred.camino.nombre}}</div>
                -->
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>
