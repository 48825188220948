<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="legal-content">
        <div class="container">
            <h2>Condiciones Legales y Política de Privacidad</h2>
            <h2>Credencial Digital del Camino de Levante</h2>
            <h4>1. Objeto</h4>
            <p><b>1.1 </b>El presente documento contiene las condiciones legales aplicables a la aplicación para
                dispositivos móviles Credencial Digital del Camino de Levante (en adelante, “App”), disponible
                en los espacios App Store y Google Play, para las versiones de sistemas operativos y
                dispositivos móviles indicados. Asimismo, determinados contenidos podrán ser accesibles a
                través del área privada de la web.</p>
            <p>Dichas aplicaciones no accederán a los datos de contactos, si bien recopilarán la fecha, hora y
                duración de los accesos a las mismas. El usuario deberá permitir el almacenamiento y
                modificación de datos en el dispositivo, el uso de servicios de conectividad y los
                requerimientos y configuraciones adicionales que se indiquen en la descarga, instalación y
                activación de dichas aplicaciones. Para poder mostrar la ruta o guía a servicios categorizados,
                deberá permitir el acceso del App a su ubicación/geoposicionamiento determinado por el
                terminal y, si lo desea, el acceso a la cámara para poder realizar fotografías y posteriormente
                almacenarlas en su área privada.</p>
            <p>Para la obtención de la Credencial Digital del Peregrino deberá necesariamente registrarse y
                permitir el acceso a la cámara y a la ubicación/geoposicionamiento para verificar el
                cumplimiento de las etapas y la obtención de los sellos.</p>
            <p><b>1.2 </b>Los servicios disponibles a través de la App son prestados por el Instituto de Turismo de la
                Región de Murcia, con CIF Q3000763G y dirección en Avda. Juana Jugan núm. 2 (edificio CCT),
                código postal 30006, Murcia (Murcia), a excepción de la obtención de la Credencial del
                Peregrino que será emitida por la Diócesis de Cartagena con CIF XXXXXXXXXXX con domicilio
                en Plaza del Cardenal Belluga, 1. 30001, Murcia y del Certificado del Peregrino (Caravacensis)
                que será emitida por la Real e Ilustre Cofradía de la Santísima y Vera Cruz de Caravaca, con CIF
                R-3000008-G y domicilio en calle de las Monjas núm. 9, bajo, código postal 30400, Caravaca de
                la Cruz (Murcia), tras la verificación del cumplimiento de los requisitos y condiciones exigibles.</p>
            <p><b>1.3 </b>La App permite el acceso a una parte pública, con información general sobre el Camino de
                Levante y agenda de actividades, información sobre servicios por categorías, identificados en
                un mapa que permite trazar una guía al destino (en caso de tener activada la
                ubicación/geolocalización), y secciones configurables. Además, se incluye un botón SOS que
                realiza una llamada al servicio de urgencias (112).</p>
            <p>La parte privada está relacionada con la Credencial del Peregrino, incluye un registro y la
                compra con códigos, información sobre el Camino de Levante y sus modalidades, y se utiliza para acreditar el cumplimiento de los requisitos para la consecución de la Credencial Digital
                tras la obtención de los distintos sellos mediante la realización de fotografías en el entorno de
                los enclaves determinados. Una vez completado el Camino, se generará automáticamente el
                Certificado del Peregrino (Caravacensis) tras la realización de la fotografía en el entorno de la
                Basílica de la Vera Cruz.</p>
            <p>El usuario puede además realizar fotografías del Camino, que se almacenarán en la App y en el
                área privada accesible desde la web. El área privada también permite descargar la
                Caravacensis, que incluirá su nombre completo, lugar de inicio del Camino de Levante, fecha y
                hora de salida y de llegada a la Basílica de la Vera Cruz. El usuario podrá compartir la
                experiencia del Camino en redes sociales (Instagram, Facebook, Twitter, WhatsApp y LinkedIn),
                lo que implica la activación de los correspondientes conectores, la vinculación con las cuentas
                del usuario en dichos espacios y el envío y publicación de contenidos e imágenes a través de
                las mismas.</p>
            <p><b>1.4 </b>Los servicios de la App están disponible para personas mayores de 16 años de edad que no
                estén legalmente incapacitadas. El usuario en todo caso debe aceptar de forma completa y sin
                reservas de estas condiciones legales, garantizando que no existe limitación o
                condicionamiento alguno que le impida el uso de estos servicios.</p>
            <h4>2. Protección de Datos</h4>
            <p><b>2.1 </b>A través de la App se recogen y tratan datos de carácter personal para gestionar el
                funcionamiento de la aplicación y sus servicios, para lo cual deberá establecer los
                correspondientes permisos en la descarga e instalación de la misma. Si desea hacer uso de
                determinadas funciones, deberá autorizar a la aplicación a acceder a la cámara, a funciones de
                búsqueda, notificaciones y actualizaciones o geolocalización.</p>
            <p>La información proporcionada en el proceso de registro y para la obtención de la Credencial de
                Peregrino será tratada por la Diócesis de Cartagena a fin de gestionar la acreditación oficial
                para peregrinar a pie, en bicicleta o a caballo hasta la Basílica de la Vera Cruz de Caravaca, y
                por la Real e Ilustre Cofradía de la Santísima y Vera Cruz de Caravaca, para la obtención del
                Certificado del Peregrino (Caravacensis) y acreditar el cumplimiento de los requisitos y
                condiciones exigibles.</p>
            <p>Los datos vinculados al uso de la aplicación en su parte pública así como para el área privada
                del usuario serán tratados por el Instituto de Turismo de la Región de Murcia, para gestionar el
                funcionamiento de la aplicación, la puesta a disposición y/o envío de notificaciones o
                comunicaciones sobre actividades relacionadas con el Camino de Caravaca de la Cruz y el
                turismo en la Región de Murcia. En caso de que lo autorice o solicite expresamente mediante
                la suscripción a nuestro boletín o newsletter, le enviaremos comunicaciones electrónicas que
                podrían incluir contenidos comerciales o promocionales, ofertas, productos o servicios de
                terceros relacionados con el turismo en la Región de Murcia. Igualmente, podremos realizar
                análisis y estadísticas del uso de la aplicación de forma agregada y global.</p>
            <p>Los datos serán conservados por los responsables del tratamiento durante el tiempo necesario
                para la realización de las gestiones que correspondan con arreglo a las finalidades señaladas, y
                posteriormente y debidamente bloqueados hasta la prescripción de las posibles
                responsabilidades legales que deriven de lo anterior. Los mencionados tratamientos de datos
                son independientes.</p>
            <p><b>2.2 </b>La base jurídica de los tratamientos anteriores es el consentimiento del usuario en relación
                al alta/registro y funcionamiento de la aplicación, y para el envío de comunicaciones
                comerciales electrónicas; y el cumplimiento y adopción de medidas precontractuales o
                contractuales en cuanto la obtención de la Credencial y Certificado del Peregrino. En los casos
                en que aparezcan campos marcados con asterisco (*), dichos datos serán de cumplimentación
                obligatoria, sin los cuales no se dará trámite a la petición correspondiente.</p>
            <p>La persona que remita sus datos por las vías señaladas manifiesta y garantiza que la totalidad
                de la información facilitada, es veraz, correcta y completa, se encuentra actualizada, y que va
                referida a su persona.</p>
            <p><b>2.3 </b>Los datos serán tratados de manera confidencial y sobre los mismos se aplicarán medidas
                técnicas y organizativas adecuadas y suficientes que garanticen la privacidad y confidencialidad
                de los mismos. No se prevén transferencias internacionales de datos.</p>
            <p><b>2.4 </b>Los afectados podrán ejercitar en cualquier momento los derechos de acceso, rectificación,
                oposición, supresión, limitación al tratamiento y portabilidad de sus datos, en las condiciones
                legalmente previstas y dirigiéndose por escrito a comunicacion@turismoregiondemurcia.es
                (frente al Instituto de Turismo de la Región de Murcia) y/o a lopd@diocesisdecartagena.org
                (frente a la Diócesis de Cartagena) o a administración@lacruzdecaravaca.es (frente a la Real e
                Ilustre Cofradía de la Santísima y Vera Cruz de Caravaca). Asimismo, le informamos de que le
                asiste el derecho a efectuar una reclamación ante las autoridades de protección de datos en
                caso de que considere que el tratamiento de sus datos no es el adecuado.</p>
            <p><b>2.5 </b>La App únicamente utilizará cookies con fines analíticos sobre la navegación y acceso a
                recursos o contenidos, incluyendo páginas o contenidos visualizados, frecuencia y repetición
                de visitas, su duración, el navegador o dispositivo móvil utilizado, de forma anónima. Dicha
                información se obtiene y procesa de forma agregada exclusivamente por el Instituto de
                Turismo de la Región de Murcia, sin que se vincule ni identifique a un usuario concreto.</p>
            <h4>3. Cambios en las condiciones legales</h4>
            <p>El Instituto de Turismo de la Región de Murcia se reserva el derecho a efectuar, en cualquier
                momento, modificaciones y actualizaciones en las condiciones y servicios prestados por medio
                de la App. Las correspondientes modificaciones serán comunicadas al usuario, que deberá
                aceptarlas para continuar utilizando estos servicios.</p>
            <h4>4. Baja y cancelación de la cuenta</h4>
            <p><b>4.1 </b>El usuario podrá cancelar su registro/cuenta en la App en cualquier momento, mediante
                los parámetros correspondientes en su perfil de usuario.</p>
            <p><b>4.2 </b>Dicha cancelación implicará el bloqueo del acceso a la parte privada de la App y la
                supresión de sus datos en los términos previstos en la normativa de protección de datos. Los
                datos serán conservados al menos durante el tiempo que sea necesario para llevar a cabo los
                trámites correspondientes, y posteriormente y debidamente bloqueados hasta la prescripción
                de las responsabilidades y efectos que procedan.</p>
        </div>
    </section>
    <app-footer></app-footer>
</div>