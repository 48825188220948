import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { faBullhorn, faMap, faMapPin, faMapSigns } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DialogService]
})
export class HomeComponent implements OnInit {

  /**
   * Marca de operación en curso
   */
  public loaded: boolean;

  images = [
    "assets/img/images-slider/img-slide-1.jpeg",
    "assets/img/images-slider/img-slide-2.jpeg",
    "assets/img/images-slider/img-slide-3.jpeg",
  ];

  faCaminos = faMapSigns;
  faRegistro = faMap;
  faServicios = faMapPin;
  faIncidencias = faBullhorn;

  constructor(
    private loadingService: LoadingService,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // establecer cargado tras inicialización de componente
    this.loaded = true;
    
    // ocultar pantalla de carga
    setTimeout(() => {
      this.loadingService.hideLoading();
    });
  }

  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
  childSelection(value: string) {
    switch(value) {
      case "login": {

        // Abrir pantalla de inicio de sesión en forma modal
        const ref = this.dialogService.open(LoginComponent, {
          width: '50%',
          showHeader: false,
          dismissableMask: true,
          styleClass: 'login-dialog'
        });

        ref.onClose.subscribe((userDo: boolean) => {
          if (userDo) {
            
          }
          else {
            
          }
        });
        break;
      }
      case "register": {

        // Abrir pantalla de registro de usuario en forma modal
        const ref = this.dialogService.open(RegisterComponent, {
          width: '80%',
          showHeader: false,
          dismissableMask: true
        });

        ref.onClose.subscribe((userDo: boolean) => {
          if (userDo) {
            
          }
          else {
            
          }
        });
        break;
      }
      default:
        break;
    }
  }

  openAppStore() {
    window.open("https://apps.apple.com/us/app/camino-de-caravaca-de-la-cruz/id6476446791", "_blank");
  }

  openGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=com.regiondemurcia.caravaca&pli=1", "_blank");
  }
}
