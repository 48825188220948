<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header [active]=4 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="security-content">
        <div class="container">
            <h2>Política de seguridad y privacidad</h2>
            <p>La Gerencia de <b>Instituto de Turismo de la Región de Murcia</b> define la seguridad de la información como la preservación de estas tres características básicas:</p>
            <ul>
                <li>Su confidencialidad, asegurando que solo las personas autorizadas pueden acceder a la información.</li>
                <li>Su integridad, asegurando que la información no es alterada durante su almacenamiento, tratamiento o comunicación.</li>
                <li>Su disponibilidad, asegurando que los usuarios autorizados tienen acceso a la información cuando lo necesitan.</li>
            </ul>
            <p>Por ello, establece su <b>Política de seguridad y privacidad</b> en base a los siguientes principios:</p>
            <ul>
                <li>La protección de los datos de carácter personal y la intimidad de las personas.</li>
                <li>La protección de los derechos de propiedad intelectual e industrial.</li>
                <li>La salvaguarda de los registros de la organización.</li>
                <li>La asignación de responsabilidades de seguridad.</li>
                <li>La formación y concienciación del personal.</li>
                <li>El registro de eventos e incidencias de seguridad y el aprendizaje de los mismos.</li>
                <li>La gestión de la continuidad del negocio.</li>
                <li>El cumplimiento de la legislación aplicable y los requisitos contractuales.</li>
            </ul>
            <h4>Consideraciones sobre el tratamiento de datos personales</h4>
            <p>Conforme a lo establecido en el artículo 13 del Reglamento (UE) 2016/679, de 27 de abril, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD), facilitamos a los interesados la siguiente información relativa al tratamiento de datos personales en la organización.</p>
            <h4>¿Quién es el Responsable del Tratamiento de sus datos?</h4>
            <p>El Responsable del Tratamiento de sus datos es Instituto de Turismo de la Región de Murcia, con CIF Q3000763G y domicilio social en Avda. Juana Jugan núm. 2 (edificio CCT), código postal 30006, Murcia (Murcia), a excepción de la obtención de la Credencial del Peregrino que será emitida por la Diócesis de Cartagena con CIF XXXXXXXXXXX con domicilio en Plaza del Cardenal Belluga, 1. 30001, Murcia y del Certificado del Peregrino (Caravacensis) que será emitida por la Real e Ilustre Cofradía de la Santísima y Vera Cruz de Caravaca, con CIF R-3000008-G y domicilio en calle de las Monjas núm. 9, bajo, código postal 30400, Caravaca de la Cruz (Murcia), tras la verificación del cumplimiento de los requisitos y condiciones exigibles.<br/>
            Puede contactar con nuestro Responsable de Privacidad en el teléfono <a href="tel:968357761">968357761</a> o a través del correo electrónico <a href="mailto:soporte@credencialdigitalcaravaca.com">soporte@credencialdigitalcaravaca.com</a>.<br/>
            Instituto de Turismo de la Región de Murcia pone a disposición del usuario el sitio web www.credencialdigitalcaravaca.com y la app Camino de Caravaca de la Cruz para ofrecer información de interés y otros servicios que pueden facilitar el Camino a los peregrinos -en adelante “usuarios”-.</p>
            <h4>¿Con qué finalidad tratamos sus datos?</h4>
            <p>Los datos solicitados en el formulario de contacto (nombre y apellidos y email) se recaban con la finalidad de gestionar las consultas de los interesados.<br/>
            El acceso a la zona privada está condicionado al registro del interesado. Los datos a facilitar de manera obligatoria son: nombre y apellidos, móvil, DNI/Pasaporte, email, ciudad de procedencia y nacionalidad. La finalidad de estos datos es ofrecer al usuario servicios de su interés que se detallan a continuación.<br/>
            A través de la app el usuario recibirá notificaciones de su interés.<br/>
            Por otro lado, la app también permite al usuario almacenar fotografías tomadas durante su viaje.  Instituto de Turismo de la Región de Murcia puede tratar estos datos con fines comerciales, por ejemplo, ofrecer al usuario un álbum de su viaje.<br/>
            Asimismo, el usuario puede incorporar sus datos bancarios (tarjeta de crédito) con la finalidad de adquirir un certificado digital.</p>
            <h4>¿Durante cuánto tiempo conservaremos sus datos?</h4>
            <p>Los datos personales facilitados a través del formulario de contacto se conservarán durante el tiempo necesario para atender la solicitud.<br/>
            Los datos personales facilitados para el registro y el contenido generado por el usuario se conservarán mientras el usuario no solicite la supresión de los mismos.</p>
            <h4>¿Cuál es la legitimación para el tratamiento de sus datos?</h4>
            <p>En ambos casos, la base jurídica que legitima el tratamiento de los datos personales es el consentimiento del interesado.</p>
            <h4>¿A qué destinatarios se comunicarán sus datos?</h4>
            <p>Los datos personales se comunicarán para dar cumplimiento a obligaciones legales aplicables.</p>
            <h4>¿Cuáles son sus derechos cuando nos facilita sus datos?</h4>
            <p>Cualquier interesado tiene derecho a acceder a sus datos personales, a solicitar la rectificación de los datos que sean inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines para los cuales fueron recogidos.<br/>
            Por motivos relacionados con su situación particular, el interesado podrá solicitar la limitación del tratamiento de sus datos u oponerse al mismo.<br/>
            Para ejercer cualquiera de sus derechos, deberá remitir una solicitud con el siguiente contenido: nombre y apellidos, copia de su DNI, petición en que se concreta su solicitud, domicilio a efectos de notificaciones, fecha y firma del solicitante y documentos acreditativos de la petición que formula. Esta información deberá ser enviada a la cuenta de correo <a href="mailto:rafael@appcamino.com">rafael@appcamino.com</a>.<br/>
            Asimismo, tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos, a través del <a href="https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaDenuncia/nuevaDenuncia.jsf">canal de denuncias de la AEPD</a>; especialmente si no ha obtenido satisfacción en el ejercicio de sus derechos.</p>
        </div>
    </section>
    <app-footer></app-footer>
</div>