import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { IsNotLoggedGuard } from './guards/is-not-logged.guard';
import { IsLoggedGuard } from './guards/is-logged.guard';
import { ProjectComponent } from './components/project/project.component';
import { FaqComponent } from './components/faq/faq.component';
import { ContactComponent } from './components/contact/contact.component';
import { RegistroComponent } from './components/private/registro/registro.component';
import { ServiciosComponent } from './components/private/servicios/servicios.component';
import { CredentialDetailComponent } from './components/private/registro/credential-detail/credential-detail.component';
import { ServicioDetailComponent } from './components/private/servicios/servicio-detail/servicio-detail.component';
import { LegalComponent } from './components/legal/legal.component';
import { SecurityComponent } from './components/security/security.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { RecoverComponent } from './components/recover/recover.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ActividadesComponent } from './components/private/actividades/actividades.component';
import { ActividadDetailComponent } from './components/private/actividades/actividad-detail/actividad-detail.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'confirm-reset/:token', component: ConfirmComponent },
  { path: 'project', component: ProjectComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'seguridad', component: SecurityComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'registro', component: RegistroComponent, canActivate: [IsLoggedGuard] },
  { path: 'servicios', component: ServiciosComponent },
  { path: 'actividades', component: ActividadesComponent },
  { path: 'credencial/:id', component: CredentialDetailComponent, canActivate: [IsLoggedGuard] },
  { path: 'servicio/:id', component: ServicioDetailComponent },
  { path: 'actividad/:id', component: ActividadDetailComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({ imports: [RouterModule.forRoot(routes, { useHash: false, initialNavigation: false})], exports: [RouterModule] })
export class AppRoutingModule { }
