import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import  { WebServiceUtils } from '../utils/web-service-utils';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActividadesCategoriasService {

  constructor(private http: HttpClient) { }

  /**
   * Listado de categorias disponibles
   * @param onSuccess respuesta OK (datos de categorias)
   * @param onError respuesta KO
   */
   public getCategoriasActividadesList(): Observable<any> {
    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE
      }
    };
    return this.http.get(environment.apiEventCategoryListUrl, opts);
  }
}
