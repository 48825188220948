<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header [active]=0 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="project-content">
        <div class="container">
            <h2>La Credencial del <b>Camino Sacro</b>, ahora con un toque <b>único</b></h2>
            <p>La Ruta de la Cruz de Caravaca es el sendero que nos lleva a la venerada Basílica-Santuario de la Vera Cruz en Caravaca de la Cruz, un lugar impregnado de historia y espiritualidad. Este camino es conocido por ser un viaje sagrado que conduce a la reliquia de la Santa Cruz, considerada una de las cinco reliquias más importantes para la cristiandad.</p>
            <p>En la majestuosa Basílica-Santuario, que alberga la Verdadera Cruz, se experimenta una conexión única con la fe y la tradición. Este emblemático lugar es también testigo de la historia, ya que según la leyenda, la Cruz de Caravaca llegó a esta tierra de manera milagrosa en el siglo XIII.</p>
            <p>La Credencial de la Cruz de Caravaca valida tu condición de peregrino en este camino espiritual. En ella, además de tus datos, se registran los sellos que marcan tu trayectoria y encuentros significativos durante la peregrinación.</p>
            <p>Este viaje es más que una travesía física; es un compromiso personal arraigado en diversas motivaciones, con la búsqueda de un significado más profundo como objetivo final. Comparte con aquellos que te rodean las experiencias y valores que extraes de este recorrido espiritual.</p>
            <p>Respeta el entorno natural durante tu trayecto, muestra fraternidad hacia tus compañeros peregrinos y las personas que encuentres en el camino. Contribuye a mantener la pureza del sendero, recogiendo cualquier desperdicio que encuentres, haciendo del Camino de la Cruz de Caravaca una empresa colectiva.</p>
            <p>Al llegar a la Basílica-Santuario, dedica el primer momento a la contemplación de la Santa Cruz. Puedes realizar una ofrenda de flores o velas como expresión de tu amor y devoción.</p>
            <p>Te sugerimos también que te detengas ante el monumento a la Santa Cruz, como testimonio de tu presencia en este lugar sagrado.</p>
            <p>También es recomendable que pongas tus pies ante el monumento a Pelayo, frente a la Basílica, como testimonio de tu presencia en la Cuna de España.</p>
            <p>Esta Credencial te otorga acceso a la Certificación Oficial de haber completado el Camino de la Cruz de Caravaca y la peregrinación al Santuario de la Vera Cruz. Puedes obtenerla en la web www.credencialdigitalcaravaca.com una vez hayas llegado a Caravaca de la Cruz.</p>
            <p></p>
            <p></p>
            <p><b>¡Embárcate en el Camino Sagrado!</b></p>
        </div>
    </section>
    <app-footer></app-footer>
</div>