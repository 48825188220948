import { Component, OnInit } from '@angular/core';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { LoadingService } from 'src/app/services/loading.service';

import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';
import { Router } from '@angular/router';

import { CredencialService } from 'src/app/services/credencial.service';
import { CredencialUsuarioDto } from 'src/app/models/credencial-usuario-dto';
import { CaminoDto } from 'src/app/models/camino-dto';
import { MedioDto } from 'src/app/models/medio-dto';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
  providers: [ConfirmationService]
})
export class RegistroComponent implements OnInit {

  /**
   * Marca de operación en curso
  */
  public loaded: boolean;

  //Títulos
  title: string;
  caption: string;

  caminos: CaminoDto[] = [];
  medios: MedioDto[] = [];
  credenciales: CredencialUsuarioDto[] = [];
  errorMessage: any = '';
  loading: boolean;
  numCredenciales: number;

  map: google.maps.Map;
  options: any;

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private credencialService: CredencialService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('registro_title');
    this.caption = this.locale.transform('registro_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    this.options = {
      center: {lat: 38.1012, lng: -1.8642},
      zoom: 7,
      mapTypeId: 'terrain',
      disableDefaultUI: true,
      maxZoom: 15,
      minZoom: 7,
      fullscreenControl: false,
      keyboardShortcuts: false
    };

    this.reloadDataCredenciales();
  }

  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
  childSelection(value: string) {
    switch(value) {
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {
            this.loadingService.setLoading();
              this.authService.logout(
                (resp: boolean) => {
                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
      });
        break;
      }
      default:
        break;
    }
  }

  setMap(event, cred: CredencialUsuarioDto) {
    cred.map = event.map;
  }

  reloadDataCredenciales() {
    this.credencialService.getCaminosList().subscribe(
      ways => {
        this.caminos = ways;
        this.credencialService.getMediosList().subscribe(
          modes => {
            this.medios = modes;
            this.credencialService.getCredentialsList().subscribe(
              creds => {
                creds.forEach(detCred => {
                  if (detCred.wayId != null && detCred.wayId != 0) {
                    this.caminos.forEach(way => {
                      if (way.id == detCred.wayId) {
                        detCred.camino = way;
                      }
                    });
                    this.medios.forEach(mode => {
                      if (mode.id == detCred.wayModeId) {
                        detCred.medio = mode;
                      }
                    });
                  }
                });
                this.numCredenciales = creds.length;
                this.credenciales = creds.sort(function sortFunction(a: CredencialUsuarioDto, b: CredencialUsuarioDto){
                  return a.purchaseDate! < b.purchaseDate! ? 1 : -1;
                });
                this.credenciales = this.credenciales.filter(element => {
                  return element.wayId != null;
                });
                this.credenciales.forEach(cred => {
                  cred.markers = [];
                  const marker = new google.maps.Marker({
                    position: {lat: cred.latitude, lng: cred.longitude},
                    animation: google.maps.Animation.DROP
                  });
                  cred.markers.push(marker);
                  const bounds = new google.maps.LatLngBounds();
                  cred.markers.forEach(marker => {
                        bounds.extend(marker.getPosition());
                    });
                  setTimeout(() => { // map will need some time to load
                    cred.map.fitBounds(bounds); // Map object used directly
                  }, 200);
                })
                this.loading = false;
                // ocultar pantalla de carga
                setTimeout(() => {
                  this.loadingService.hideLoading();
                });
              },
              error => this.errorMessage = <any>error);

          },
          error => this.errorMessage = <any>error);
      },
      error => this.errorMessage = <any>error);
  }

  handleMapClick(event, cred: CredencialUsuarioDto) {
    this.router.navigateByUrl('/credencial/' + cred.id);
  }
}
