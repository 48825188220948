import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { LocalePipe } from 'src/app/pipes/locale.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SessionService } from 'src/app/services/session.service';
import { LocalStorageUtils } from 'src/app/utils/local-storage-utils';
import { ServicioDto } from 'src/app/models/servicio-dto';
import { CategoriaDto } from 'src/app/models/categoria-dto';

import { ServiciosService } from 'src/app/services/servicios.service';
import { ServiciosCategoriasService } from 'src/app/services/servicios-categorias.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss'],
  providers: [ConfirmationService]
})
export class ServiciosComponent implements OnInit {

  @ViewChild('dt') table: Table;

  /**
   * Marca de operación en curso
   */
  public loaded: boolean;

   //Títulos
  title: string;
  caption: string;

  servicios: ServicioDto[] = [];
  servicio: any;
  errorMessage: any = '';
  options: any;
  loading: boolean;
  first = 0;
  rows = 25;
  markers: any[];
  numServicios: number;

  map: google.maps.Map;
  categorias: CategoriaDto[];
  cats: SelectItem[];

  constructor(private locale: LocalePipe,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private serviciosService: ServiciosService,
    private categoriasService: ServiciosCategoriasService) { }

  ngOnInit(): void {

    // establecer no cargado hasta inicialización de componente
    this.loaded = false;

    // Añadir titulos a la cabecera
    this.title = this.locale.transform('servicios_title');
    this.caption = this.locale.transform('servicios_caption');

    // establecer cargado tras inicialización de componente
    this.loaded = true;

    this.reloadDataCategorias();
    this.options = {
      center: {lat: 38.1012, lng: -1.8642},
      zoom: 7,
      mapTypeId: 'terrain',
      disableDefaultUI: true,
      maxZoom: 15,
      minZoom: 7,
      fullscreenControl: false,
      keyboardShortcuts: false
    };
  }

  setMap(event) {
    this.map = event.map;
  }

  /**
   * Función que recibe un parámetro desde elementos hijos y ejecuta la función correspondiente
   * @param value tipo de pulsación generada
   */
   childSelection(value: string) {
    switch(value) {
      case "logout": {
        this.confirmationService.confirm({
          message: '¿Estás seguro que deseas cerrar sesión?',
          header: 'Cerrar sesión',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'CONTINUAR',
          rejectVisible: false,
          acceptButtonStyleClass: 'dialog-button',
          accept: () => {

            this.loadingService.setLoading();

              this.authService.logout(
                (resp: boolean) => {

                  // ocultar loader dinámico
                  this.loadingService.hideLoading();

                  // actualizar sesión
                  this.sessionService.saveSessionInfo(null);

                  // borrar sesión persistente
                  LocalStorageUtils.clear();

                  // reiniciar la navegación
                  this.router.navigate(['home']);
                },
                (resp: boolean, err: HttpErrorResponse) => {

                });
          },
          reject: () => {}
        });
        break;
      }
      default:
        break;
    }
  }

  drawMarkers(datos: any[]) {
    this.markers = [];
    if ( datos && datos.length > 0 ) {
      datos.forEach(
        s => {
          if (s.latitude != null && s.latitude != 0) {
            this.markers.push(s.marker)
          }
        }
      );
      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => {
            bounds.extend(marker.getPosition());
        });

      setTimeout(() => { // map will need some time to load
          this.map.fitBounds(bounds); // Map object used directly
      }, 200);

    } else {
      this.markers = [];
    }
  }

  reloadDataServicios() {
    this.serviciosService.getPoisList().subscribe(
      async poi => {
        this.numServicios = poi.length;
        this.servicios = poi.map(servicio => {
          servicio.marker = this.generateMarker(servicio);
          return servicio;
        });
        this.drawMarkers(this.servicios);
        // ocultar pantalla de carga
        setTimeout(() => {
          this.loadingService.hideLoading();
        });
      },
      error => this.errorMessage = <any>error
    );
  }

  reloadDataCategorias() {
    this.categoriasService.getCategoriasPoisList().subscribe(
      cats => {
        this.categorias = cats;
        this.categorias.sort(function sortFunction(a, b){
          return a.name > b.name ? 1 : -1;
        });
        this.cats = [];
        this.categorias.forEach(cat => {
          var appItem: SelectItem = { label: cat.name, value: cat.name, icon: cat.icon}
          this.cats.push(appItem);
        });
        this.reloadDataServicios();
      },
      error => this.errorMessage = <any>error
    );
  }

  generateMarker(servicio: any) {
    // Cambiar imagen de marcador según categoria
    const image = servicio.category.icon;

    const infoWindowContent = `<div id="infoWindowContent">
                                 <div class="infoWindowTitle"><strong><a href="/#/servicio/${servicio.id}">${servicio.name}</a></strong></div>
                                   <div id="infoWindowBodyContent">${servicio.municipality}, ${servicio.province}</div>
                                   <div id="infoWindowCategory"><img src="${image}"/>${servicio.category.name}</div>
                               </div>`;

    const infoWindow = new google.maps.InfoWindow({
     content: infoWindowContent,
    });

    const marker = new google.maps.Marker({
      position: {lat: servicio.latitude, lng: servicio.longitude},
      animation: google.maps.Animation.DROP,
      icon: image
    });

    marker.addListener('click', () => {
      if(marker.get('open')){
        infoWindow.close();
        marker.set('open', false);
      } else {
        infoWindow.open(this.map, marker);
        marker.set('open', true);
      }
    });

    infoWindow.addListener('closeclick', () => {
      marker.set('open', false);
    });

    return marker;
  }

  onTableEvent(event: any) {
    if (event.filteredValue) {
      
      //this.drawMarkers(event.filteredValue, 0, this.rows);
    }

    // Estamos ordenando
    if(event.order) {
      //this.drawMarkers(this.table.value, this.first, this.rows);
    }
  }

  onPageChange(event: any) {
    if (this.table.filteredValue) {
      
      //this.drawMarkers(this.table.filteredValue, event.first, event.rows);
    } else{
      //this.drawMarkers(this.table.value, event.first, event.rows);
    }
  }

  showServicioInfo(servicio: ServicioDto) {
    this.router.navigateByUrl('/servicio/' + servicio.id);
  }

}
