import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import  { WsSuccessFunction, WsErrorFunction, WebServiceUtils } from '../utils/web-service-utils';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
import { CredencialUsuarioDto } from '../models/credencial-usuario-dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CredencialService {

  constructor(private http: HttpClient,
    private sessionService: SessionService) { }

  /**
   * Listado de credenciales del usuario
   * @param onSuccess respuesta OK (datos de credenciales)
   * @param onError respuesta KO
   */
  getCredentialsList(): Observable<any> {
    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo()?.accessToken
      }
    };
    return this.http.get(environment.apiCredentialListUrl, opts);
  }

  /**
   * Detalle de una credencial
   * @param idCredencial identificador de la credencial
   * @param onSuccess respuesta OK (datos de credencial)
   * @param onError respuesta KO
   */
   public getCredencialDetail(id: number): Observable<any> {
    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo()?.accessToken
      }
    };
    return this.http.get<any>(environment.apiCredentialDetailUrl + `/${id}`, opts);
  }

  /**
   * Descarga de una credencial en PDF (certificado)
   * @param idCredencial identificador de la credencial
   */
  public downloadCredencialPdf(cred: CredencialUsuarioDto): Observable<any> {
    const body = {
      credentialId: cred.id,
      cancel: false
    }
    return this.http.post(environment.apiCredentialDownloadUrl, body, { 
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo()?.accessToken
        },
        observe: 'body',
        responseType: 'blob'
    });
  }

  /**
   * Descarga del album de una credencial en PDF 
   * @param idCredencial identificador de la credencial
   */
   public downloadAlbumPdf(cred: CredencialUsuarioDto): Observable<any> {
    const body = {
      credentialId: cred.id,
      cancel: false
    }
    return this.http.post(environment.apiCredentialDownloadAlbumUrl, body, { 
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo()?.accessToken
        },
        observe: 'body',
        responseType: 'blob'
    });
  }

  public getCaminosList(): Observable<any> {
    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo()?.accessToken
      }
    };
    return this.http.get(environment.apiWayListUrl, opts);
  }

  public getMediosList(): Observable<any> {
    const opts = {
      headers: {
        'Content-Type': WebServiceUtils.CONTENT_TYPE,
        'Authorization': 'Bearer ' + this.sessionService.loadSessionInfo()?.accessToken
      }
    };
    return this.http.get(environment.apiModeListUrl, opts);
  }
}