<div class="main-content">
    <p-scrollTop [threshold]="200"></p-scrollTop>
    <app-header [active]=3 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    <section class="contact-map">
        <agm-map [zoom]="zoom" [latitude]="lat" [longitude]="lng">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
    </section>
    <section class="contact-content">
        <div class="contact-form">
            <form id="form-contact1" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="form-group user-name">
                    <fa-icon [icon]="faUser"></fa-icon>
                    <input type="text" class="form-control" formControlName="contactFormName" required id="name-contact-1" placeholder="Nombre y apellidos">
                </div>

                <div class="form-group user-email">
                    <fa-icon [icon]="faEmail"></fa-icon>
                    <input type="email" class="form-control" formControlName="contactFormEmail" required id="email-contact" placeholder="Email">
                </div>
                <div class="form-group user-message">
                    <fa-icon [icon]="faMessage"></fa-icon>
                    <textarea class="form-control" formControlName="contactFormMessage" required id="message-contact" placeholder="Tu Mensaje..."></textarea>
                </div>
                <div class="form-group">
                    <p>Doy mi consentimiento para que Instituto de Turismo de la Región de Murcia trate mis datos personales con la siguiente finalidad:</p>
                    <div class="check">
                        <p-checkbox [formControl]="contactForm.controls['contactFormCopy']"></p-checkbox>
                        <label>Gestionar las consultas de los usuarios.</label>
                    </div> 
                </div>
                <button type="submit" id="send-contact-1" [disabled]="disabledSubmitButton" class="btn-contact">CONTACTAR</button>
            </form>
            <div class="legal-info">
                <table>
                    <tr>
                        <td>Responsable del tratamiento</td>
                        <td>Instituto de Turismo de la Región de Murcia</td>
                    </tr>
                    <tr>
                        <td>Finalidades</td>
                        <td>Gestionar las consultas de los usuarios</td>
                    </tr>
                    <tr>
                        <td>Legitimación</td>
                        <td>Consentimiento del interesado para el tratamiento de sus datos en base a la finalidad anterior</td>
                    </tr>
                    <tr>
                        <td>Destinatarios</td>
                        <td>Los datos no se comunicarán, salvo para el cumplimiento de obligaciones legales</td>
                    </tr>
                    <tr>
                        <td>Derechos</td>
                        <td>Acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad de los datos a través de rafael@appcamino.com</td>
                    </tr>
                    <tr>
                        <td>Información adicional</td>
                        <td>Puede consultar la información adicional y detallada sobre este tratamiento <a routerLink="/seguridad">aquí.</a></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="contact-info">
            <h3 class="heading-cont">Información de contacto</h3>
            <span class="devider-left"></span>
            <address class="contact-address">
                <span>Avda. Juana Jugan núm. 2 (edificio CCT), 30006 Murcia, Murcia</span> 
                <span><strong>TELÉFONO:</strong> (+34) 968 357 761</span> 
                <span><strong>EMAIL:</strong><a href="mailto:soporte@credencialdigitalcaravaca.com"> soporte@credencialdigitalcaravaca.com</a></span> 
                <span><strong>WEB:</strong><a href="#"> www.credencialdigitalcaravaca.com</a></span>
            </address>
        </div>
    </section>
    <app-footer></app-footer>
</div>
