<div class="main-content">
    <app-header [active]=1 (newItemEvent)="childSelection($event)"></app-header>
    <app-sub-header [title]="title" [caption]="caption"></app-sub-header>
    
    <section class="servicio-content">
        <div class="container">
            <div class="media-content">
                <div class="servicio-map">
                    <p-gmap #gmap (onMapReady)="setMap($event)" [options]="options" [overlays]="markers" [style]="{'width':'100%','height':'400px'}"></p-gmap>
                </div>
                
                <div class="servicio-image">
                    <img *ngIf="servicio != null && servicio.image == null" src="assets/img/placeholder.png" alt="imagen del servicio"/>
                    <img *ngIf="servicio != null && servicio.image != null" [src]="servicio.image" alt="imagen del servicio"/>
                </div>
            </div>
            <div class="title-content" *ngIf="servicio">
                <h2>{{servicio.name}}</h2>
                <span class="subtitle"><img [alt]="servicio.category.name" src="{{servicio.category.icon}}" width="32" style="vertical-align: middle" />{{servicio.category.name}}</span>
            </div>
            <div class="main-info-title">
                <span>Información general</span>
            </div>
            <div class="main-info-content" *ngIf="servicio">
                <div class="main-left">
                    <div class="item" *ngIf="servicio.address != null"><i class="pi pi-map-marker"></i>{{servicio.address}}</div>
                    <div class="item" *ngIf="servicio.calendario != null && servicio.calendario != ''"><i class="pi pi-calendar"></i>{{servicio.calendario}}</div>
                    <div class="item" *ngIf="servicio.horario != null && servicio.horario != ''"><i class="pi pi-clock"></i>{{servicio.horario}}</div>
                </div>
                <div class="main-right">
                    <div class="item" *ngIf="servicio.web != null && servicio.web != ''"><i class="pi pi-desktop"></i><a href="https://{{servicio.web}}" target="_blank">{{servicio.web}}</a></div>
                    <div class="item" *ngIf="servicio.email != null && servicio.email != ''"><i class="pi pi-envelope"></i><a href="mailto:{{servicio.email}}">{{servicio.email}}</a></div>
                    <div class="item" *ngIf="servicio.phone != null && servicio.phone != ''"><i class="pi pi-phone"></i><a href="tel:{{servicio.phone}}">{{servicio.phone}}</a></div>
                </div>
            </div>
            <!--<div class="main-info-title" *ngIf="servicio.categoria.id == 28">
                <span>Datos del albergue</span>
            </div>
            <div class="main-info-content" *ngIf="servicio.categoria.id == 28">
                <div class="albergue-item" [ngClass]="servicio.cocina == true ? 'selected' : ''">
                    <img src="assets/img/kitchen.png" alt="cocina"/>
                    <span class="title">Cocina</span>
                </div>
                <div class="albergue-item" [ngClass]="servicio.comedor == true ? 'selected' : ''">
                    <img src="assets/img/dinner.png" alt="cocina"/>
                    <span class="title">Comedor</span>
                </div>
                <div class="albergue-item" [ngClass]="servicio.lavadora == true ? 'selected' : ''">
                    <img src="assets/img/household.png" alt="cocina"/>
                    <span class="title">Lavadora</span>
                </div>
                <div class="albergue-item" [ngClass]="servicio.secadora == true ? 'selected' : ''">
                    <img src="assets/img/dryer.png" alt="cocina"/>
                    <span class="title">Secadora</span>
                </div>
                <div class="albergue-item" [ngClass]="servicio.barRestaurante == true ? 'selected' : ''">
                    <img src="assets/img/fork.png" alt="cocina"/>
                    <span class="title">Bar/Rest.</span>
                </div>
                <div class="albergue-item" [ngClass]="servicio.tienda == true ? 'selected' : ''">
                    <img src="assets/img/pawprint.png" alt="cocina"/>
                    <span class="title">Animales</span>
                </div>
                <div class="albergue-item" [ngClass]="servicio.bicicletas == true ? 'selected' : ''">
                    <img src="assets/img/bike.png" alt="cocina"/>
                    <span class="title">Bicis</span>
                </div>
                <div class="albergue-item" [ngClass]="servicio.reservas == true ? 'selected' : ''">
                    <img src="assets/img/reservation.png" alt="cocina"/>
                    <span class="title">Reservas</span>
                </div>
                <div class="albergue-item" [ngClass]="servicio.internet == true ? 'selected' : ''">
                    <img src="assets/img/wifi.png" alt="cocina"/>
                    <span class="title">Internet</span>
                </div>
            </div>
            <div class="price-content" *ngIf="servicio.categoria.id == 28">
                <span class="price" *ngIf="servicio.precio != null">{{servicio.precio}} €</span>
                <span class="aforo" *ngIf="servicio.numeroPlazas != null">{{servicio.numeroPlazas}} plazas disponibles</span>
            </div>-->

            <div class="general-description" *ngIf="servicio" [innerHtml]="servicio.description"></div>
        </div>
    </section>
    <app-footer></app-footer>
</div>
